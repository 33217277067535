/* Imports */
import { Injectable, NgZone } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from "@angular/router";
import isEmpty from 'lodash/isEmpty';
import retry from 'async-retry';

import { AuthenticationService, GlobalService } from "@shared/services";
import { OrganizationService } from "@shared/services";
import { Subscription } from "@shared/services/subscription.service";
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";

const MATCH_KEY = true as any; // typescript doesn't like booleans as index values, but jokes on typescript, it's perfectly valid and we are taking advantage of that and the fact that duplicate keys overwrite one another in objects, so the **only** `true` key will be the **only** value to pick

enum UserState {
	loggedIn,
	loggedOut,
	empty
}

function getUsersState(user) {
	if (user === null) return UserState.loggedOut;
	else if (isEmpty(user)) return UserState.empty;
	else return UserState.loggedIn;
}

@Injectable()
export class UserGuard  {
	currentUserState: UserState = UserState.loggedOut;
	constructor(
		private _router: Router,
		private _authenticationService: AuthenticationService,
		private _orgService: OrganizationService,
		private _ngZone: NgZone,
	) {} // End-of constructor

	async canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Promise<boolean | UrlTree> {
		try {
			const { user } = this._authenticationService;
			if (this.currentUserState !== getUsersState(user)) {
				this.currentUserState = getUsersState(user);
				// set the initial feature flag context
				await flagLayer.setContext(GlobalService.getFeatureFlagContext());
			}

			const isLoggedIn = !!(user?.id && user?.email);

			// this code block should be deleted when the remove-subscription-required flag is removed
			if(!flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement)) {
				const orgList = !isLoggedIn
					? []
					: this._orgService.userOrgList ??
					(await retry(
						(bail) => // `bail` is a function provided by `async-retry` to signify when something has _actually_ gone wrong and you don't want the retries to continue
							this._orgService
								.getList()
								.catch((e) => {
									console.error(e);
									bail([]); // if something truely went wrong, we should skip the retries
								})
								.then((orgs) => {
									if (orgs.some(Subscription.getSub)) return orgs; // a valid org *needs* a subscription
									throw new Error("No Org Yet"); // signals another retry
								}),
						{ retries: 5, maxTimeout: 1000 } // `retry` sets random wait times between attempts, we cap that at 1 second.
					).catch(() => [])); // `retry` with throw the error we give it, if it fails all 5 times

				// does having an org matter?
				const hasAnOrg = orgList?.length;

				// subscription, no need
				const hasASubscription = orgList.some(Subscription.getSub);

				const isRegistrationRoute = state.url.includes("/register");
				const isLoginRoute = state.url === "/login";

				const routeMapping = {
					[MATCH_KEY]: false,
					[((isRegistrationRoute || isLoginRoute) &&
						hasASubscription &&
						isLoggedIn) as any]: "/", // final result will be '/'
					[(isRegistrationRoute && !isLoggedIn) as any]: state.url.replace('/', ''), // Removes the first / for matchedRoute
					[(!isRegistrationRoute &&
						!hasASubscription) as any]: "register",
					[(!isRegistrationRoute && !isLoggedIn) as any]: "login",
					[(!hasASubscription && isLoggedIn) as any]: "support/no_sub",
					[(!hasAnOrg && isLoggedIn) as any]: "support/no_org",
					[(isLoggedIn &&
						user.email_verified !== 1) as any]: "verification"
				};

				const matchedRoute = "/" + routeMapping[MATCH_KEY];

				if (!routeMapping[MATCH_KEY] || matchedRoute === state.url || (matchedRoute.includes('verification') && state.url.includes('verification'))) {
					return true;
				}
				console.warn('Navigating user to: ', matchedRoute)
				this._ngZone.run(() => this._router.navigate([matchedRoute]));

			} else { // code path when no subscription is needed

				const orgList = !isLoggedIn
					? []
					: this._orgService.userOrgList ??
						await this._orgService.getList()
							.catch((e) => {
								console.error(e);
								return [];
							})
							.then((orgs) => {
								return orgs;
							});

				const hasAnOrg = orgList?.length;

				const url = state.url.split("?")[0]; // get the url without query params
				const isRegistrationRoute = url.includes("/register");
				const isLoginRoute = url.includes("/login");

				const routeMapping = {
					[MATCH_KEY]: false,
					[((isRegistrationRoute || isLoginRoute) &&
						isLoggedIn) as any]: "/", // final result will be '/'
					[(isRegistrationRoute && !isLoggedIn) as any]: url.replace('/', ''), // Removes the first / for matchedRoute
					[(!isRegistrationRoute && !isLoggedIn) as any]: "login",
					[(!hasAnOrg && isLoggedIn) as any]: "support/no_org",
					[(isLoggedIn &&
						user.email_verified !== 1) as any]: "verification"
				};

				const matchedRoute = "/" + routeMapping[MATCH_KEY];

				if (!routeMapping[MATCH_KEY] || matchedRoute === url || (matchedRoute.includes('verification') && url.includes('verification'))) {
					return true;
				}
				console.warn('Navigating user to: ', matchedRoute)
				this._ngZone.run(() => this._router.navigate([matchedRoute], {queryParams: next.queryParams}));
			}
			return false; // return false if we need to redirect, true if we do not

		} catch (e) {
			console.error(e);
			return Promise.resolve(true);
		}
	} // End-of canActivate

	async canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		return true;
	} // End-of canActivateChild

	canLoad(): boolean {
		let isLoggedIn = this._authenticationService.user;

		return isLoggedIn;
	} // End-of canLoad
} // End-of class UserGuard
